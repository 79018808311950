import * as React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Gallery from "../components/Gallery";

const AdvertisingPage = () => (
  <Layout descriptionText={"Advertising"}>
    <Seo title="advertising" />
    <Gallery categoryName="advertising" />
  </Layout>
);

export default AdvertisingPage;
